import { PanelsGenerationActionTypes } from "../actions/panelsGenerationActions";
import { PanelGenerationStageNotification } from "../entities/panelGenerationStageNotification";

export type State = {
    operationName: string;
    isInProgress: boolean;
    completedStages: PanelGenerationStageNotification[];
};

const initialState: State = {
    operationName: "",
    isInProgress: false,
    completedStages: [],
};

export const isGenerationInProgress = function (state: State): boolean {
    return state.isInProgress;
};

export const getGenerationOperationName = function (state: State): string {
    return state.operationName;
};

export const getCompletedGenerationStages = function (state: State): PanelGenerationStageNotification[] {
    return state.completedStages;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state: State = initialState, action: PanelsGenerationActionTypes): State {
    switch (action.type) {
        case "START_PANELS_GENERATION_OPERATION": {
            return { ...state, operationName: action.operationName, isInProgress: true, completedStages: [] };
        }

        case "COMPLETE_GENERATION": {
            return { ...state, isInProgress: false, completedStages: [] };
        }

        case "PUSH_GENERATION_STAGES": {
            return {
                ...state,
                completedStages: [
                    ...state.completedStages,
                    ...action.newStages,
                ],
            };
        }

        case "PUSH_GENERATION_PROGRESS_STAGE": {
            return {
                ...state,
                completedStages: [
                    ...state.completedStages,
                    action.stage,
                ],
            };
        }

        default:
            return state;
    }
};