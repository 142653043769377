// use fixed version of the viewer to avoid usage of untested viewer version
const viewerVersion = "7.104.0";

export const viewerCss = `https://developer.api.autodesk.com/modelderivative/v2/viewers/${viewerVersion}/style.min.css`;
export const viewerJs = `https://developer.api.autodesk.com/modelderivative/v2/viewers/${viewerVersion}/viewer3D.min.js`;

export const guid = function (format = "xxxxxxxxxxxx") {

    var d = new Date().getTime();

    const guid = format.replace(
        /[xy]/g,
        function (c) {
            const r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            // eslint-disable-next-line no-mixed-operators
            return (c === "x" ? r : (r & 0x7 | 0x8)).toString(16);
        });

    return guid;
}

export const round = function (value: number, digits?: number) {
    let factor = 1;

    const roundDigits = digits || 0;

    for (let i = 0; i < roundDigits; ++i) {
        factor = 10 * factor;
    }

    return Math.round(factor * value) / factor;
}

export const getQueryStringParams = () => {
    return new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop: string) => searchParams.get(prop),
    });
}