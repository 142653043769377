import { combineReducers } from 'redux';
import profileReducer, { State as ProfileState } from './profileReducer'
import * as profile from './profileReducer'
import * as uilogin from './loginReducer'
import loginReducer, { State as LoginState } from './loginReducer'
import panelsReducer, { FolderEditorState, State as PanelState } from './panelsReducer';
import * as panels from "./panelsReducer";
import panelAutocadSourceModelReducer, { State as AutocadEditorState } from "./panelAutocadSourceModelReducer";
import * as autocadEditor from "./panelAutocadSourceModelReducer";
import processingReducer, { State as ProcessingState } from "./processingReducer";
import * as processing from "./processingReducer";
import reportsReducer, { State as ReportsState } from "./reportsReducer";
import * as reports from "./reportsReducer"
import panelInstancesReducer, { State as PanelInstancesState } from "./panelInstancesReducer";
import * as panelInstances from "./panelInstancesReducer"
import panelsGenerationReducer, { State as PanelsGenerationState } from "./panelsGenerationReducer";
import * as panelGeneration from "./panelsGenerationReducer";
import { IPanel, IPanelDrawings } from '../entities/panel';
import { IFolder } from '../entities/folder';
import { IBomRow } from '../entities/bomRow';
import { PanelGenerationStageNotification } from '../entities/panelGenerationStageNotification';


export const mainReducer = combineReducers({
    profile: profileReducer,
    login: loginReducer,
    panels: panelsReducer,
    autocadEditor: panelAutocadSourceModelReducer,
    processing: processingReducer,
    reports: reportsReducer,
    panelInstances: panelInstancesReducer,
    panelGeneration: panelsGenerationReducer,
});

export type State = {
    profile: ProfileState;
    panels: PanelState;
    login: LoginState;
    autocadEditor: AutocadEditorState;
    processing: ProcessingState;
    reports: ReportsState;
    panelInstances: PanelInstancesState,
    panelGeneration: PanelsGenerationState
}

// LOGIN
export const getLoginResults = function (state: State) {
    return uilogin.getLoginResults(state.login);
}

export const canSignIn = function (state: State): boolean {
    return uilogin.canSignIn(state.login);
}

export const getUserName = function (state: State): string {
    return uilogin.getUserName(state.login);
}

export const getPassword = function (state: State): string {
    return uilogin.getPassword(state.login);
}

export const getUserData = function (state: State) {
    return {
        name: getUserName(state),
        password: getPassword(state)
    }
}

// PROFILE
export const isLoggedIn = function (state: State): "unknown" | "logged" | "not-logged" {
    return profile.isLoggedIn(state.profile);
}

export const getProfile = function (state: State): ProfileState {
    return state.profile;
};

// PANELS
export const getPanels = function (state: State): IPanel[] {
    return panels.getPanels(state.panels);
}

export const getSelectedPanel = function (state: State): IPanel | null {
    return panels.getSelectedPanel(state.panels);
}

export const getFolders = function (state: State): IFolder[] {
    return panels.getFolders(state.panels);
}

export const isTopFolder = function (state: State): boolean {
    return panels.isTopFolder(state.panels);
}

export const getCurrentFolder = function (state: State): IFolder | null {
    return panels.getCurrentFolder(state.panels);
}

export const getFolderStack = function (state: State): IFolder[] {
    return panels.getFolderStack(state.panels);
}

export const getFolderEditorState = function (state: State): FolderEditorState {
    return panels.getFolderEditorState(state.panels);
}

export const getEditorFolder = function (state: State): IFolder | null {
    return panels.getEditorFolder(state.panels);
}

export const getPanelDrawings = function (state: State): IPanelDrawings[] {
    return panels.getPanelDrawings(state.panels);
}

export const getBomExcelFileUrl = function (state: State): string | null {
    return panels.getBomExcelFileUrl(state.panels);
}

export const getBom = function (state: State): IBomRow[] {
    return panels.getBom(state.panels);
}

export const isBomVisible = function (state: State): boolean {
    return panels.isBomVisible(state.panels);
}

// ACAD EDITOR
export const isModelUploadDialogVisible = function (state: State): boolean {
    return autocadEditor.isModelUploadDialogVisible(state.autocadEditor);
}

export const isCustomModelUploadDialogVisible = function (state: State): boolean {
    return autocadEditor.isCustomModelUploadDialogVisible(state.autocadEditor);
}

export const getModelSource = function (state: State): autocadEditor.ModelSource {
    return autocadEditor.getModelSource(state.autocadEditor);
}

export const getNewModelCreationError = function (state: State): string | null {
    return autocadEditor.getNewModelCreationError(state.autocadEditor);
}

export const isCornerModelUpload = function (state: State): boolean {
    return autocadEditor.isCornerModelUpload(state.autocadEditor);
}

// PROCESSING
export const isProcessingPaneVisible = function (state: State): boolean {
    return processing.isProcessingPaneVisible(state.processing);
}

export const getProcessingMessage = function (state: State): string | null {
    return processing.getProcessingMessage(state.processing);
}

export const isProcessingFailed = function (state: State): boolean {
    return processing.isProcessingFailed(state.processing);
}

export const getProcessingFailure = function (state: State): string | null {
    return processing.getProcessingFailure(state.processing);
}

export const getProcessingFailureTitle = function (state: State): string {
    return processing.getProcessingFailureTitle(state.processing);
}

// REPORTS
export const isReportDialogVisible = function (state: State): boolean {
    return reports.isReportDialogVisible(state.reports);
}

export const getReport = function (state: State): string | null {
    return reports.getReport(state.reports);
}

// PANEL INSTANCES
export const isInstancesDialogCreationVisible = function (state: State): boolean {
    return panelInstances.isInstancesDialogCreationVisible(state.panelInstances);
}

export const getInstancesCreationError = function (state: State): string | null {
    return panelInstances.getInstancesCreationError(state.panelInstances);
}

// PANEL GENERATION
export const isGenerationInProgress = function (state: State): boolean {
    return panelGeneration.isGenerationInProgress(state.panelGeneration);
}

export const getGenerationOperationName = function (state: State): string {
    return panelGeneration.getGenerationOperationName(state.panelGeneration);
}

export const getCompletedGenerationStages = function (state: State): PanelGenerationStageNotification[] {
    return panelGeneration.getCompletedGenerationStages(state.panelGeneration);
}