import { TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from "@mui/lab";
import Typography from "@weave-design/typography";
import { PanelGenerationStageNotification } from "../entities/panelGenerationStageNotification";

type Props = {
    stage: PanelGenerationStageNotification;
    isLastStage: boolean;
}

export const PanelGenerationProcessingStage = ({ stage, isLastStage }: Props) => {
    return <TimelineItem sx={{ fontFamily: "ArtifaktElement, sans-serif;", minHeight: "35px" }}>
        <TimelineSeparator>
            <TimelineDot sx={{ transform: "scale(0.7)" }} />
            {!isLastStage && <TimelineConnector sx={{ transform: "scale(1, 30)" }} />}
        </TimelineSeparator>
        <TimelineContent><Typography>{stage.message}</Typography></TimelineContent>
    </TimelineItem>;
}