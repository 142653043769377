import { WorkerAction, WorkerResultMessage } from "./panelsGeneratorWorker";
import { PanelGenerationStageNotification } from "../entities/panelGenerationStageNotification";
import { IForgeFileStorageObject } from "../entities/forgeFileStorageObject";
import repo from "../Repository";

export class PanelsGenerator {
    private readonly worker: Worker;

    constructor(
        private readonly onPanelGenerated: OnPanelGeneratedAction,
        private readonly onFailure: OnOperationFailedAction,
        private readonly onProgress: OnProgressAction
    ) {
        this.worker = new Worker(new URL("./panelsGeneratorWorker.ts", import.meta.url), { type: "module" });
        this.postWorkerMessage({
            action: "initialize",
            accessToken: repo.getAccessToken(),
        });
        this.worker.onmessage = this.onWorkerMessage.bind(this);
    }

    generatePanel(storage: IForgeFileStorageObject, folderId: string, productionLod: boolean) {
        this.postWorkerMessage({
            action: "start-panel-generation",
            storage,
            folderId,
            productionLod,
        });
    }

    generateCorner(storage: IForgeFileStorageObject, folderId: string, productionLod: boolean) {
        this.postWorkerMessage({
            action: "start-corner-generation",
            storage,
            folderId,
            productionLod,
        });
    }

    generateCustomPanel(storage: IForgeFileStorageObject, assemblyName: string, folderId: string) {
        this.postWorkerMessage({
            action: "start-custom-panel-generation",
            storage,
            assemblyName,
            folderId,
        });
    }

    dispose() {
        this.worker.onmessage = null;
        this.worker.terminate();

        // @ts-expect-error help GC
        delete this.onPanelGenerated;
        // @ts-expect-error help GC
        delete this.onFailure;
        // @ts-expect-error help GC
        delete this.onProgress;
    }

    private postWorkerMessage(message: WorkerAction) {
        this.worker.postMessage(message);
    }

    private onWorkerMessage(event: MessageEvent<WorkerResultMessage>) {
        const data = event.data;

        switch (data.action) {
            case "generated-successful": {
                this.onPanelGenerated(data.panelId);
                this.dispose();
                break;
            }

            case "operation-failed": {
                this.onFailure(data.panelId, data.message);
                this.dispose();
                break;
            }

            case "generation-in-progress": {
                this.onProgress(data.panelId, data.newLogItems);
                break;
            }
        }
    }
}

type OnPanelGeneratedAction = (panelId: string) => void;
type OnOperationFailedAction = (panelId: string, message: string) => void;
type OnProgressAction = (panelId: string, newLogItems: PanelGenerationStageNotification[]) => void;