import axios, { AxiosError } from 'axios';
import { IBomRow } from './entities/bomRow';
import { IFolder } from './entities/folder';
import { IForgeFileStorageObject } from './entities/forgeFileStorageObject';
import { IPanel, IPanelDrawings } from './entities/panel';
import { IInspectorCheckPoint, IInspectorNote } from './entities/panelInspector';
import { BasicResponse, BasicItemResponse, BasicItemsResponse } from './entities/responses';
import { IUser } from './entities/user';
import { ProcessingStatus } from './entities/processingStatus';
import { PanelGenerationStageNotification } from './entities/panelGenerationStageNotification';

const AuthorizationHeader = 'Authorization';
const unknownFailureMessage = "Server error. Repeat operation later";

class Repository {
    private _accessToken: string | undefined;

    async validateAccessToken(): Promise<boolean> {
        const response = await axios.post("/login/validate");

        return response.data.item;
    }

    async loadProfile(): Promise<IUser> {
        const response = await axios.get("/login/profile");

        return response.data.item;
    }

    async login(userData: { name: string, password: string }): Promise<BasicItemResponse<string>> {
        const formData = new FormData();

        formData.append('name', userData.name);
        formData.append('password', userData.password);

        const result = await axios.post('/login', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return result.data;
    }

    async loadPanels(folderId: string): Promise<IPanel[]> {
        const response = await axios.get(`/folder/${folderId}/panels`);

        return response.data.items;
    }

    async loadTopFolders(): Promise<BasicItemsResponse<IFolder>> {
        const response = await axios.get("/top-folders");

        return response.data;
    }

    async loadChildrenFolders(parentFolderId: string): Promise<BasicItemsResponse<IFolder>> {
        const response = await axios.get(`/folders/${parentFolderId}`);

        return response.data;
    }

    async uploadFileToBucket(file: File): Promise<IForgeFileStorageObject> {
        return this.uploadToBucket(file, "/forge/create-storage");
    }

    async uploadCustomPanelModel(file: File): Promise<IForgeFileStorageObject> {
        return this.uploadToBucket(file, "/custom-panel/create-storage");
    }

    async startGeneratingPanelFromDwg(storage: IForgeFileStorageObject, folderId: string,
        productionLod: boolean, connectionId: string): Promise<BasicResponse> {
        try {
            const result = await axios({
                url: `/folder/${folderId}/generate-panel-from-dwg?connectionId=${connectionId}&productionLod=${productionLod}`,
                method: "POST",
                data: storage
            });

            return result.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async startGeneratingCornerPanelFromDwg(storage: IForgeFileStorageObject, folderId: string,
        productionLod: boolean, connectionId: string): Promise<BasicResponse> {
        try {
            const result = await axios({
                url: `/folder/${folderId}/generate-corner-from-dwg?connectionId=${connectionId}&productionLod=${productionLod}`,
                method: "POST",
                data: storage
            });

            return result.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async startGeneratingCustomPanel(storage: IForgeFileStorageObject, assemblyName: string,
        folderId: string, connectionId: string): Promise<BasicResponse> {
        type CreateCustomPanelCommand = { storage: IForgeFileStorageObject, fileName: string };

        const command: CreateCustomPanelCommand = { storage, fileName: assemblyName };

        const url = `/folder/${folderId}/generate-custom-panel?connectionId=${connectionId}`;

        try {
            const result = await axios<BasicResponse>({
                url,
                method: "POST",
                data: command
            });

            return result.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message) {
                return e.response.data;
            }

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async createPanelsFromTestInputs(): Promise<BasicResponse> {
        const result = await axios.post("/automation/create-test-panels");

        return result.data;
    }

    async loadUserPanelsStaticsReport(folderId: string): Promise<string> {
        const result = await axios.get(`/folder/${folderId}/failing-panels-report`);

        return result.data;
    }

    async removePanel(panelId: string): Promise<BasicResponse> {
        const result = await axios({
            url: `/panels/${panelId}`,
            method: "delete"
        });

        return result.data;
    }

    async createFolder(parentFolderId: string | null, name: string): Promise<BasicResponse> {
        const result = await axios({
            url: "/folders",
            method: "post",
            data: { parentFolderId, name }
        });

        return result.data;
    }

    async updateFolder(folderId: string, name: string): Promise<BasicResponse> {
        const result = await axios({
            url: `/folders/${folderId}`,
            method: "patch",
            data: { name }
        });

        return result.data;
    }

    async removeFolder(folderId: string): Promise<BasicResponse> {
        const result = await axios({
            url: `/folders/${folderId}`,
            method: "delete"
        });

        return result.data;
    }

    async findPanelDrawings(panelId: string): Promise<BasicItemsResponse<IPanelDrawings>> {
        const response = await axios.get("/panel/" + panelId + "/drawings");

        return response.data;
    }

    async updatePanelInstancesCount(panelId: string, instancesCount: number): Promise<BasicResponse> {
        const response = await axios({
            url: `/panels/${panelId}/instances`,
            method: "patch",
            data: { instancesCount }
        });

        return response.data;
    }

    async createExcelBomReport(folderId: string): Promise<BasicItemResponse<string>> {
        const response = await axios.get(`/folder/${folderId}/bom-excel`);

        return response.data;
    }

    async findFolderBom(folderId: string): Promise<BasicItemsResponse<IBomRow>> {
        const response = await axios.get(`/folder/${folderId}/bom-report`);

        return response.data;
    }

    async pushPanelToDextallStudio(id: string, type: "panel" | "corner", image: File): Promise<BasicResponse> {
        const formData = new FormData();

        formData.append("image", image);

        const result = await axios.putForm(`https://studio.dextall.com/api/integration/panels-generator/${type}-template/${id}`, formData);

        return result.data;
    }

    async findPanel(id: string): Promise<BasicItemResponse<IPanel>> {
        try {
            const response = await axios.get<BasicItemResponse<IPanel>>(`/panels/${id}/inspector`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: "Failed to get the panel", item: null };
        }
    }

    async findPanelInspectorCheckPoints(panelId: string): Promise<BasicItemsResponse<IInspectorCheckPoint>> {
        try {
            const response = await axios.get<BasicItemsResponse<IInspectorCheckPoint>>(`/panel/${panelId}/control-check`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: "Failed to get panel inspector check points", items: null };
        }
    }

    async findPanelInspectorNotes(panelId: string): Promise<BasicItemsResponse<IInspectorNote>> {
        try {
            const response = await axios.get<BasicItemsResponse<IInspectorNote>>(`/panel/${panelId}/inspector-notes`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: "Failed to get panel inspector check points", items: null };
        }
    }

    async createPanelInstances(folderId: string, file: File): Promise<BasicResponse> {
        const formData = new FormData();

        formData.append("folderId", folderId);
        formData.append("sourceExcelFile", file);

        const result = await axios.post<BasicResponse>("/panels/create-instances", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return result.data;
    }

    async startPanelsStickyNotesGeneration(folderId: string): Promise<BasicItemResponse<string>> {
        try {
            const response = await axios.post<BasicItemResponse<string>>(`/folder/${folderId}/sticky-notes`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: "Failed to start panels sticky notes with QR codes generation", item: null };
        }
    }

    async getPanelStickyNotesTaskGenerationStatus(storageId: string): Promise<BasicItemResponse<ProcessingStatus>> {
        try {
            const response = await axios.get<BasicItemResponse<ProcessingStatus>>(`/folder/sticky-notes/${storageId}/status`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: "Failed to get QR codes generation task status", item: null };
        }
    }

    async createPanelStickyNotesDownloadableUrl(storageId: string): Promise<BasicItemResponse<string>> {
        try {
            const response = await axios.post<BasicItemResponse<string>>(`/folder/sticky-notes/${storageId}/downloadable`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: "Failed to download panels sticky notes QR codes DWG file", item: null };
        }
    }

    async findNotifications(connectionId: string, panelId: string, timeStamp: number | undefined = undefined)
        : Promise<BasicItemsResponse<PanelGenerationStageNotification>> {
        const timeStampParameter = timeStamp !== undefined
            ? `&timeStamp=${timeStamp}`
            : "";

        const url = `/api/notifications/${panelId}?connectionId=${connectionId}${timeStampParameter}`;

        try {
            const response = await axios.get<BasicItemsResponse<PanelGenerationStageNotification>>(url);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: "Failed to query notifications", items: null };
        }
    }

    getAccessToken() { return this._accessToken; }

    setAccessToken(value: string) {
        this._accessToken = value;
        axios.defaults.headers.common[AuthorizationHeader] = `Bearer ${value}`;
    }

    forgetAccessToken() {
        delete axios.defaults.headers.common[AuthorizationHeader];
        delete this._accessToken;
    }

    hasAccessToken() { return !!this._accessToken; }

    private async uploadToBucket(file: File, createStorageApiEndpoint: string): Promise<IForgeFileStorageObject> {
        const createStorageResponse = await axios.post<BasicItemResponse<IForgeFileStorageObject>>(createStorageApiEndpoint);

        const storage = createStorageResponse.data.item!;

        const fileName = file.name;

        await axios({
            url: storage.signedUrl,
            method: "put",
            data: file,
            headers: {
                "Content-Disposition": "attachment; filename=\"" + encodeURIComponent(fileName) + "\""
            }
        });

        return storage;
    }
}

export default new Repository();