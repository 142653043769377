import { guid } from "../components/shared";
import { PanelGenerationStageNotification } from "../entities/panelGenerationStageNotification";

type StartGenerationActionType = {
    type: "START_PANELS_GENERATION_OPERATION";
    operationName: string;
};

type PushGenerationStagesActionType = {
    type: "PUSH_GENERATION_STAGES";
    newStages: PanelGenerationStageNotification[];
};

type CompleteGenerationActionType = {
    type: "COMPLETE_GENERATION";
};

type PushProgressStageActionType = {
    type: "PUSH_GENERATION_PROGRESS_STAGE",
    stage: PanelGenerationStageNotification;
};

export type PanelsGenerationActionTypes = StartGenerationActionType | PushGenerationStagesActionType | CompleteGenerationActionType | PushProgressStageActionType;

export const startGenerationOperation = (operationName: string): StartGenerationActionType => {
    return {
        type: "START_PANELS_GENERATION_OPERATION",
        operationName,
    };
};

export const pushGenerationStages = (newStages: PanelGenerationStageNotification[]): PushGenerationStagesActionType => {
    return {
        type: "PUSH_GENERATION_STAGES",
        newStages,
    };
};

export const pushSyntheticGenerationStage = (message: string, panelId: string): PushProgressStageActionType => {
    return {
        type: "PUSH_GENERATION_PROGRESS_STAGE",
        stage: {
            id: guid(),
            panelId,
            message,
            time: Date.now(),
            type: "progress",
        },
    };
};

export const completeGeneration = (): CompleteGenerationActionType => {
    return {
        type: "COMPLETE_GENERATION",
    };
};