import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Modal from '@weave-design/modal';
import ProgressBar from '@weave-design/progress-bar';
import { PanelGenerationStageNotification } from '../entities/panelGenerationStageNotification';
import merge from "lodash.merge";
import { Timeline, timelineItemClasses } from '@mui/lab';
import { isGenerationInProgress, getGenerationOperationName, getCompletedGenerationStages, State } from "../reducers/mainReducer";
import "./processingPane.css";
import "./panelGenerationProcessingPane.css"
import { PanelGenerationProcessingStage } from './panelGenerationProcessingStage';

type Props = {
    isVisible: boolean;
    message: string;
    stages: PanelGenerationStageNotification[];
};

export const PanelGenerationProcessingPane = ({ isVisible, message, stages }: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!(isVisible && containerRef.current)) {
            return;
        }

        containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }, [stages, isVisible]);

    return <div className="processing-pane">
        <Modal
            open={isVisible} title="Processing..." stylesheet={modalStyles}>
            <div className="processingProgressPane">
                <div className="processingProgressContent">
                    <div className="processingProgressImage" style={{ backgroundImage: "url('/Assembly_icon.svg')" }} />
                    <div className="processingProgressAction" style={{ fontWeight: "bold" }}>
                        <ProgressBar className="processingProgressBar" />
                    </div>
                </div>
                <div className="processingProgressTimePrompt">This operation could take several minutes</div>
                <div className="processingProgressStatusPane panels-generation-status"><strong>Status</strong>: {message}</div>
                <div className="panels-generation-timeline-container"
                    ref={containerRef}>
                    <Timeline
                        sx={{
                            [`& .${timelineItemClasses.root}:before`]: {
                                flex: 0,
                                padding: 0,
                            },
                        }}>
                        {stages.map((x, i) => <PanelGenerationProcessingStage
                            key={`processing-stage-${x.id}-${x.time}`}
                            stage={x}
                            isLastStage={i === stages.length - 1} />)}
                    </Timeline>
                </div>
            </div>
        </Modal>
    </div>
}

export default connect(function (store: State) {
    return {
        isVisible: isGenerationInProgress(store),
        message: getGenerationOperationName(store),
        stages: getCompletedGenerationStages(store),
    }
}, {})(PanelGenerationProcessingPane)

const modalStyles = (styles: any) =>
    merge(styles, {
        modal: {
            window: { // by design
                width: "400px",
                height: "400px",
                top: "calc(50% - 200px)"
            },
            bodyContent: {
                overflow: "hidden" // no scrollbar
            }
        }
    });