import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { IFolder } from '../entities/folder';
import { selectBreadCrumbFolder } from "../actions/panelsActions";
import { State, getFolderStack } from '../reducers/mainReducer';
import { FolderBreadCrumb } from './folderBreadCrumb';
import "./panels.css"

interface IComponentProps {
    folders: IFolder[];
    selectBreadCrumbFolder: (folder: IFolder | null) => void;
}

export class FoldersBreadCrumb extends Component<IComponentProps> {
    render(): ReactNode {
        const foldersInBreadCrumb = 2;

        const folders = this.props.folders;

        const breadCrumbs: React.ReactNode[] = [];

        const selectFolder = (folder: (IFolder | null)) => this.props.selectBreadCrumbFolder(folder);

        breadCrumbs.push((<FolderBreadCrumb folder={null} isCurrent={folders.length === 0} select={selectFolder} key="top-folder" />));

        if (folders.length > foldersInBreadCrumb)
            breadCrumbs.push(<FolderBreadCrumb folder={null} isCurrent={false} select={() => { }} title="..." key="next" />);

        for (const folder of folders.slice(-foldersInBreadCrumb))
            breadCrumbs.push(<FolderBreadCrumb folder={folder} isCurrent={folder === folders[folders.length - 1]} select={selectFolder} key={folder.id} />);

        return (<div className='container breadcrumb-container'>{breadCrumbs}</div>);
    }
}

export default connect(function (store: State) {
    return {
        folders: getFolderStack(store)
    };
}, { selectBreadCrumbFolder })(FoldersBreadCrumb);